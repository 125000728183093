import React, { Component, useState, useEffect } from 'react';

export default class ButtonLoader extends Component {
  
  state = {
    loading: false, waitTime: 5
  };

  axios = require('axios');

  config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}api/user/verify_email`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : {} 
  };

  timer = 1000;

  verifyEmail = async(email) => {
    if (!email.endsWith('.edu')) {
	alert('Email address should end with ".edu"');
    	return;
    }

    this.config.data = JSON.stringify({
      "email":email,
    });

    this.handleSubmit();

    console.log("inside verifyEmail():",email,"\n");	
        if(!this.state.loading) {
          this.setState({loading: true});
          this.timer = setInterval(() => {
                    this.countdown();
                }, 1000);
        }
  }
 
  componentWillUnmount() {
      window.clearInterval(this.timer);
  }

  countdown = () => {
      console.log("inside countdown()\n")
      if(this.state.waitTime === 0) { 
          this.setState({ loading: false, waitTime: 5 });
          clearInterval(this.timer);
      } else {
          console.log(this.state.waitTime, "\n");
          this.setState({ waitTime: this.state.waitTime - 1});
      }
  }

  handleSubmit = async() => {
    console.log("handleSubmit alert");
    this.axios(this.config)
    .then(function (response) {
if (response.data.exists) {
      alert('Email already exists');
    }
else{
      console.log(JSON.stringify(response.data));
if(JSON.stringify(response.data) == "{\"error\":\"该邮箱已被使用\"}") {
	alert('Email already exists');	
}
   }
console.log("for debug");
 })
    .catch(function (error) {
alert('Email already exists');     
 console.log(error);
    });
  };

  render() {
   const { loading } = this.state;
    return (
        <button style= {{ marginTop: "20px" }} onClick={()=>this.verifyEmail(this.props.email)} disabled={loading}>
	  {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
		  
            />
          )}
	  {loading && <p>{this.state.waitTime}</p>}
          {!loading && <div>Send</div>}
        </button>
    );
  }
}
