import React, {useState, useContext} from "react";
import "./ChatBubble.css"; // Import the CSS file for ChatBubble
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { IoPersonAddOutline, IoPersonAdd } from "react-icons/io5";
import { BsPersonX } from "react-icons/bs";
const backend_url = "https://ai-api.umiuni.com:8183";

export function parseTime(timestamp_string) {
  const date = new Date(timestamp_string);
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  const seconds =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * # Chat Bubble Element
 * @param {{content: string,
 * self_flag: boolean,
 * timestamp: number || undefined,
 * sender: undefined || {
 * pfp: string, name: string}}} parameters
 * @returns JSX Element
**/
export function ChatBubble({
  content,
  self_flag,
  timestamp,
  sender,
}) {
  const { auth } = useContext(AuthContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [friendData, setFriendData] = useState({ to: null, from: null, status: null });
  const [decryptedAuthId, setDecryptedAuthId] = useState(null);

  const togglePopup = async () => {
    setIsPopupVisible(!isPopupVisible);
    if (!isPopupVisible) {
      try {
        const response = await axios.post(
          "https://us.umiuni.com:5002/api/user/decode_userid",
          JSON.stringify({ encodedString: auth.userid }),
          { headers: { "Content-Type": "application/json" } }
        );
        setDecryptedAuthId(response.data.result);
      } catch (error) {
        console.error("Error decrypting user ID:", error);
      }
    }
    checkFriend();
  };

  const decryptUserId = async (userId) => {
    try {
      const data = JSON.stringify({
        encodedString: userId,
      });
  
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://us.umiuni.com:5002/api/user/decode_userid",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
  
      const response = await axios.request(config);
      console.log(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error decrypting user ID:", error);
      throw error;
    }
  };

  const checkFriend = async () => {
    try {
      const decryptedFromUserId = await decryptUserId(auth.userid);
      const decryptedToUserId = await decryptUserId(sender.senderId);
      const data = {
        from_user_id: decryptedFromUserId,
        to_user_id: decryptedToUserId,
      };
      const result = await axios.post(`${backend_url}/friends/check`, data, {
        headers: { "Content-Type": "application/json" },
      });
      console.log(result.data);
      setFriendData(result.data);
    } catch (error) {
      console.error(error);
    }
  }

  const addFriend = async () => {
    try {
      const decryptedFromUserId = await decryptUserId(auth.userid);
      const decryptedToUserId = await decryptUserId(sender.senderId);
      const data = {
        from_user_id: decryptedFromUserId,
        to_user_id: decryptedToUserId,
      };
      await axios.post(`${backend_url}/friends/add`, data, {
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      console.error(error);
    }
  }

  const acceptFriend = async () => {
    try {
      const decryptedFromUserId = await decryptUserId(auth.userid);
      const decryptedToUserId = await decryptUserId(sender.senderId);
      const data = {
        from_user_id: decryptedFromUserId,
        to_user_id: decryptedToUserId,
      };
      await axios.post(`${backend_url}/friends/accept`, data, {
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      console.error(error);
    }
  }

  const removeFriend = async () => {
    try {
      const decryptedFromUserId = await decryptUserId(auth.userid);
      const decryptedToUserId = await decryptUserId(sender.senderId);
      const data = {
        from_user_id: decryptedFromUserId,
        to_user_id: decryptedToUserId,
      };
      await axios.post(`${backend_url}/friends/remove`, data, {
        headers: { "Content-Type": "application/json" },
      });
    } catch (error) {
      console.error(error);
    }
  }

  const message_class_string = self_flag ? "received-message" : "sent-message";
  return (
    <>
      <div className="time">{parseTime(timestamp)}</div>
      {sender && (
        <div
          className="userProfile"
          style={{ display: !self_flag ? "none" : "block" }}
        >
          <img
            src={`https://oss.umiuni.com/${sender.pfp}`}
            className="avatar"
            onClick={togglePopup}
          />
          <div className="senderName">{sender.name}</div>
          {isPopupVisible && (
            <div className="popup">
              <button className="close-button" onClick={() => setIsPopupVisible(false)}>×</button>
                <div className="popupContent">
                <img
                  src={`https://oss.umiuni.com/${sender.pfp}`}
                  className="popAvatar"
                />
                <p className="popupName">{sender.name}</p>
              </div>
              <div className="buttonGroup">
                {friendData.status === "none" || friendData.status === "removed" ? (
                  <button onClick={addFriend} className="iconButton">
                    <IoPersonAddOutline />
                  </button>
                ) : friendData.status === "pending" && friendData.to === decryptedAuthId ? (
                  <button onClick={acceptFriend} className="iconButton">
                    <IoPersonAdd />
                  </button>
                ) : friendData.status === "accepted" || (friendData.status === "pending" && friendData.from === decryptedAuthId) ? (
                  <button onClick={removeFriend} className="iconButton">
                    <BsPersonX />
                  </button>
                ) : null}
              </div>
            </div>
          )}
        </div>
      )}
      <div className={`message ${message_class_string}`}>{content}</div>
    </>
  );
}
